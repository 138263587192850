import React from 'react';

import { useTranslation } from 'react-i18next';

import Layout from '../../../components/MenuLayout';

export default () => {
  const { t } = useTranslation();

  return (
    <Layout type="settings">
      <span>{t('not.implemented')}</span>
    </Layout>
  );
};
